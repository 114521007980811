const Filter = ({ ...props }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.85337 4C6.2876 4 5.82896 4.45865 5.82896 5.02441C5.82896 5.59018 6.2876 6.04883 6.85337 6.04883L17.9756 6.04883C18.5414 6.04883 19 5.59018 19 5.02441C19 4.45865 18.5414 4 17.9756 4L6.85337 4ZM3.34109 4C2.77532 4 2.31668 4.45865 2.31668 5.02441C2.31668 5.59018 2.77532 6.04883 3.34109 6.04883C3.90686 6.04883 4.36551 5.59018 4.36551 5.02441C4.36551 4.45865 3.90686 4 3.34109 4ZM5.82896 10.0001C5.82896 9.43437 6.2876 8.97573 6.85337 8.97573L17.9756 8.97573C18.5414 8.97573 19 9.43437 19 10.0001C19 10.5659 18.5414 11.0246 17.9756 11.0246L6.85337 11.0246C6.2876 11.0246 5.82896 10.5659 5.82896 10.0001ZM3.33995 8.97573C2.77418 8.97573 2.31553 9.43437 2.31553 10.0001C2.31553 10.5659 2.77418 11.0246 3.33995 11.0246C3.90572 11.0246 4.36436 10.5659 4.36436 10.0001C4.36436 9.43437 3.90572 8.97573 3.33995 8.97573ZM5.82896 14.9759C5.82896 14.4101 6.2876 13.9515 6.85337 13.9515L17.9756 13.9515C18.5414 13.9515 19 14.4101 19 14.9759C19 15.5416 18.5414 16.0003 17.9756 16.0003L6.85337 16.0003C6.2876 16.0003 5.82896 15.5416 5.82896 14.9759ZM3.33995 13.9515C2.77418 13.9515 2.31553 14.4101 2.31553 14.9759C2.31553 15.5416 2.77418 16.0003 3.33995 16.0003C3.90572 16.0003 4.36436 15.5416 4.36436 14.9759C4.36436 14.4101 3.90572 13.9515 3.33995 13.9515Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Filter
